(function () {
    const reels = Array.from(document.querySelectorAll('.o-reel'));
    const toggleOverflowClass = elem => {
        elem.classList.toggle('is-overflowing', elem.scrollWidth > elem.clientWidth);
    };

    for (let reel of reels) {
        if ('ResizeObserver' in window) {
            new ResizeObserver(entries => {
                toggleOverflowClass(entries[0].target);
            }).observe(reel);
        }
    }
})();
